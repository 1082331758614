import { useState } from 'react';
import './App.scss';

function App() {
  const [modalState, setModalState] = useState(false)

  return (
    <div className="obs">
      <div className="obs_modal" data-active={modalState}>
        <div className="obs_modal__form">
          <button className="obs_modal__exit"
            onClick={() => setModalState(false)}></button>
          <iframe 
            title="obs_contact__form" 
            src="https://docs.google.com/forms/d/e/1FAIpQLScBmqT4yVbBAUDGd7bpGO3nVkwfTenbFlytPRYce2PufBiKzg/viewform?embedded=true"  
            scrolling="no" 
            frameBorder="0" 
            height="100%"
            width="100%"
            marginHeight="0" 
            marginWidth="0">Loading…</iframe>
        </div>
      </div>
      <div className="obs_character"></div>

      <div className="obs_brand">
        <div className="obs_brand__name"></div>
        <div className="obs_sugar__powder"></div>
        <div className="obs_brand__logo"></div>
        <a href="/#" 
          className="obs_brand__action"
          onClick={(evt) => {
            evt.preventDefault(); 
            setModalState(true)}
        }>Tell Me When Your Open!</a>
      </div>
      
      <div className="obs_opening">
        <h2 className="obs_opening__date">
          <span className="obs_opening__date___monthdate">Fall</span>
          <span className="obs_opening__date___monthdate">2021</span>
        </h2>
      </div>
    </div>
  );
}

export default App;
